<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Servicios</h2>
            <p>Nuestros Servicios</p>
        </div>
    </div>
</div>

<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bx-conversation bg-13c4a1'></i>
                    <h3>Consultoría IT</h3>
                    <p>Ofrecemos servicios de consultoría IT para ayudar a tu empresa a implementar las mejores soluciones tecnológicas.</p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bx-mobile bg-6610f2'></i>
                    <h3>Aplicaciones Móviles</h3>
                    <p>Desarrollamos aplicaciones móviles personalizadas para satisfacer las necesidades específicas de tu negocio.</p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bxs-badge-check bg-ffb700'></i>
                    <h3>Soluciones IT</h3>
                    <p>Implementamos soluciones IT eficientes y seguras para optimizar el rendimiento de tu empresa.</p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bx-laptop bg-9c27b0'></i>
                    <h3>Desarrollo Web</h3>
                    <p>Diseñamos y desarrollamos sitios web modernos y funcionales que se adaptan a tus necesidades.</p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bx-cart bg-3f51b5'></i>
                    <h3>eCommerce</h3>
                    <p>Te ayudamos a crear y gestionar tu tienda online para aumentar tus ventas y llegar a más clientes.</p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bxs-dashboard bg-e91e63'></i>
                    <h3>Gestión de Proyectos</h3>
                    <p>Ofrecemos servicios de gestión de proyectos para asegurar que tus proyectos se completen a tiempo y dentro del presupuesto.</p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bx-data bg-fc3549'></i>
                    <h3>Hosting Web</h3>
                    <p>Proporcionamos servicios de hosting web seguros y confiables para tu sitio web o aplicación.</p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bxs-info-circle bg-00d280'></i>
                    <h3>Soporte Técnico</h3>
                    <p>Brindamos soporte técnico continuo para garantizar que tus sistemas funcionen sin problemas.</p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bx-cog bg-ff612f'></i>
                    <h3>SEO y Marketing de Afiliados</h3>
                    <p>Mejoramos la visibilidad de tu sitio web en los motores de búsqueda y gestionamos programas de marketing de afiliados.</p>
                    
                </div>
            </div>
        </div>
    </div>
</section>

<section class="video-presentation-area ptb-100">
    <div class="container">
        
        <div class="contact-cta-box mwidth-1000 wow animate__animated animate__fadeInUp" data-wow-delay="1s">
            <h3>¿Tienes alguna pregunta sobre nosotros?</h3>
            <p>No dudes en contactarnos.</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-edit-alt"></i> Contáctanos<span></span></a>
        </div>
    </div>
    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
</section>
