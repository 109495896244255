<div class="saas-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container max-width-1290">
                <div class="row align-items-center pt-5">
                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image mt-70">
                            <img src="assets/img/saas-shape/arrow.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s" alt="arrow">
                            <img src="assets/img/saas-shape/box1.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="box1">
                            <img src="assets/img/saas-shape/boy1.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="0.6s" alt="boy1">
                            <img src="assets/img/saas-shape/boy2.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="boy2">
                            <img src="assets/img/saas-shape/boy3.png" class="wow animate__animated animate__bounceIn" data-wow-delay="0.6s" alt="boy3">
                            <img src="assets/img/saas-shape/digital-screen.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s" alt="digital-screen">
                            <img src="assets/img/saas-shape/filter1.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="filter1">
                            <img src="assets/img/saas-shape/filter2.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="filter2">
                            <img src="assets/img/saas-shape/filter3.png" class="wow animate__animated animate__rotateIn" data-wow-delay="0.6s" alt="filter3">
                            <img src="assets/img/saas-shape/girl1.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="girl1">
                            <img src="assets/img/saas-shape/girl2.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="girl2">
                            <img src="assets/img/saas-shape/monitor.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="monitor">
                            <!-- Main image -->
                            <img src="assets/img/saas-shape/main-image.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="main-image.png">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content pl-4">
                            <h1>Innovación y tecnología</h1>
                            <p>En <strong>Brineos</strong> desarrollamos software a medida, integrando nuevas maneras hacer realidad lo que sueñas. Descubre más y únete a nuestra familia.</p>
                            <div class="banner-btn">
                                <div class="d-flex">
                                    <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> ¿Brineamos? <span></span></a>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape-rotate rotateme">
        <img src="assets/img/saas-shape/shape-rotate.png" alt="img">
    </div>
    <div id="particles-js"></div>
</div>

<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container max-width-1290">
        <div class="section-title">
            <h2>Garantizamos</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".1s">
                    <i class='bx bx-cog bg-13c4a1'></i>
                    <h3>Optimizado al usuario</h3>
                    <p>Utilizamos las últimas tecnologías orientadas a los usuarios.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".2s">
                    <i class='bx bxs-check-shield bg-6610f2'></i>
                    <h3>Seguridad</h3>
                    <p>Garantizamos la protección de tus datos utilizando lo mejor del mercado en seguridad.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                    <i class='bx bx-timer bg-ffb700'></i>
                    <h3>Ahorro del tiempo</h3>
                    <p>Preocúpate de dedicar más tiempo en hacer crecer tu negocio y optimiza tus procesos con nuestras soluciones.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".4s">
                    <i class='bx bx-badge-check bg-fc3549'></i>
                    <h3>Soporte y mantención</h3>
                    <p>Consulta por nuestros distintos planes de mantención y soporte de nuestros proyectos.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".5s">
                    <i class='bx bxs-lock-open bg-00d280'></i>
                    <h3>Acceso rápido</h3>
                    <p>Accede inmediatamente a la información realmente que necesitas, mejorando la productividad y la toma de decisiones.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow animate__animated animate__fadeInLeft" data-wow-delay=".6s">
                    <i class='bx bxs-droplet-half bg-ff612f'></i>
                    <h3>Infraestructura en la nube</h3>
                    <p>Ofrecemos servicios basados en la nube de GCLoud, que incluyen almacenamiento escalable y asegurando una infraestructura de primer nivel.</p>
                </div>
            </div>
        </div>
    </div>
</section>






<section class="services-area ptb-100 bg-f4f6fc">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets\img\home-saas\interfaz-image.jpg" alt="imagen">
                </div>
            </div>
            <div class="services-content it-service-content">
                <div class="content">
                    <div class="fun-facts-inner-content">
                        <h2>Construye una Interfaz Hermosa en tu Aplicación</h2>
                        <p>En nuestra empresa, nos enfocamos en crear interfaces intuitivas y visualmente atractivas que mejoren la experiencia del usuario y aumenten la eficiencia.</p>
                        <ul>
                            <li><i class="bx bx-badge-check"></i>Código Profesional</li>
                            <li><i class="bx bx-badge-check"></i>Videollamadas Ilimitadas</li>
                            <li><i class="bx bx-badge-check"></i>Agregar Contacto Favorito</li>
                            <li><i class="bx bx-badge-check"></i>Filtro de Cámara</li>
                            <li><i class="bx bx-badge-check"></i>Formato de Codificación Inicial</li>
                        </ul>
                        <a routerLink="/about" class="default-btn black-btn"><i class="bx bxs-arrow-to-right"></i>Leer Más <span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape9">
        <img src="assets/img/shape/shape9.png" alt="imagen">
    </div>
</section>



<section class="free-trial-area pb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>Siempre intentamos brindarte el mejor soporte posible</h2>
            <p>Tus clientes te amarán.</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Contáctanos<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="imagen"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="imagen"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="imagen"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="imagen"></div>
</section>
