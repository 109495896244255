<footer class="footer-area">
    <div class="divider"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <!-- <div class="logo">
                        <a routerLink="/"><img src="" alt="imagen"></a>
                    </div> -->
                    <h3>Brineos</h3>
                    <p>
                        Nos especializamos en brindar soluciones tecnológicas efectivas y a medida, diseñadas específicamente para las demandas de tu empresa. Estamos comprometidos en ofrecer un servicio de alta calidad que facilite la optimización de tus procesos y te ayude a lograr tus metas.</p>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Empresa</h3>
                    <ul class="services-list">
                        <li><a routerLink="/">Sobre Nosotros</a></li>
                        <li><a routerLink="/">Servicios</a></li>
                        <li><a routerLink="/">Características</a></li>
                        <li><a routerLink="/">Precios</a></li>
                        <li><a routerLink="/">Últimas Noticias</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Soporte</h3>
                    <ul class="support-list">
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Política de Privacidad</a></li>
                        <li><a routerLink="/">Términos y Condiciones</a></li>
                        <li><a routerLink="/">Comunidad</a></li>
                        <li><a routerLink="/">Contáctenos</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Información de Contacto</h3>
                    <ul class="footer-contact-info">
                        <!-- <li>Ubicación: <a href="https://goo.gl/maps/MQ78iGP5g9VgZcJ38" target="_blank">6th Floor, Elegant Tower, York, USA</a></li>
                         -->
                        <li>Email: <a href="mailto:contacto&#64;brineos.com">contacto&#64;brineos.com</a></li>
                        
                        <li>Teléfono: <a href="tel:+56973979282">+569 7397 9282</a></li>
                        <li>Teléfono: <a href="tel:+56962403879">+569 6240 3879</a></li>
                    </ul>
                    <ul class="social">
                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li> -->
                        <li><a href="https://x.com/brineoslab" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/brineos" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com/brineoslab" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>© Brineos </p>
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>
