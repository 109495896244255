<div class="{{navbarClass}}">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/img/brineos_logo.svg" alt="logo">
            </a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>        

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Inicio</a></li>
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Quiénes somos</a></li>
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/services" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Servicios</a></li>                    

                    
                   
                    

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contacto</a></li>
                </ul>
                <div class="others-options">
                    <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i>Contáctanos<span></span></a>
                    <!-- <a routerLink="/log-in" class="optional-btn"><i class="bx bx-log-in"></i>Log In<span></span></a> -->
                </div>
            </div>
        </nav>
    </div>
</div>