<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services</h2>
            <p>Our Services</p>
        </div>
    </div>
</div>

<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>IT Consultancy</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a routerLink="/services-details" class="read-more">Read More <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-mobile'></i>
                    </div>
                    <h3>Mobile Applications</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a routerLink="/services-details" class="read-more">Read More <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bxs-badge-check'></i>
                    </div>
                    <h3>IT Solutions</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a routerLink="/services-details" class="read-more">Read More <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-laptop'></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a routerLink="/services-details" class="read-more">Read More <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-cart'></i>
                    </div>
                    <h3>eCommerce</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a routerLink="/services-details" class="read-more">Read More <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bxs-dashboard'></i>
                    </div>
                    <h3>Project Management</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a routerLink="/services-details" class="read-more">Read More <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-data'></i>
                    </div>
                    <h3>Web Hosting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a routerLink="/services-details" class="read-more">Read More <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bxs-info-circle'></i>
                    </div>
                    <h3>Technical Support</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a routerLink="/services-details" class="read-more">Read More <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <h3>SEO, Affiliate Marketing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a routerLink="/services-details" class="read-more">Read More <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonials-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Whats Our Clients Said About <span>Jexsa</span></h2>
        </div>
        <div class="testimonials-slides owl-carousel owl-theme">
            <div class="single-testimonials-item">
                <div class="client-info">
                    <img src="assets/img/author-image/img6.jpg" alt="image">
                    <h3>Michel John</h3>
                    <span>CEO at Envato</span>
                </div>
                <div class="testimonials-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                <div class="client-info">
                    <img src="assets/img/author-image/img7.jpg" alt="image">
                    <h3>Sarah Taylor</h3>
                    <span>CEO at EnvyTheme</span>
                </div>
                <div class="testimonials-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                <div class="client-info">
                    <img src="assets/img/author-image/img8.jpg" alt="image">
                    <h3>James Andy</h3>
                    <span>CEO at 3S</span>
                </div>
                <div class="testimonials-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                <div class="client-info">
                    <img src="assets/img/author-image/img8.jpg" alt="image">
                    <h3>James Andy</h3>
                    <span>CEO at 3S</span>
                </div>
                <div class="testimonials-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                <div class="client-info">
                    <img src="assets/img/author-image/img7.jpg" alt="image">
                    <h3>Sarah Taylor</h3>
                    <span>CEO at EnvyTheme</span>
                </div>
                <div class="testimonials-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Why choose us to watch this video know more</h2>
        </div>
        <div class="video-box">
            <img src="assets/img/video-bg.jpg" class="main-image" alt="image">
            <a href="https://www.youtube.com/watch?v=0gv7OC9L2s8" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
            <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
            <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
            <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
            <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
            <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
            <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        </div>
        <div class="funfacts-inner">
            <div class="row">
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow animate__animated animate__fadeInLeft" data-wow-delay=".2s">
                        <i class='bx bx-list-check'></i>
                        <h3><span class="odometer" data-count="900">00</span><span class="sign-icon">+</span></h3>
                        <p>Completed Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow animate__animated animate__fadeInLeft" data-wow-delay=".4s">
                        <i class='bx bx-smile'></i>
                        <h3><span class="odometer" data-count="850">00</span><span class="sign-icon">+</span></h3>
                        <p>Happy Clients</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow animate__animated animate__fadeInLeft" data-wow-delay=".6s">
                        <i class='bx bx-grid-small'></i>
                        <h3><span class="odometer" data-count="120">00</span><span class="sign-icon">+</span></h3>
                        <p>Ongoing Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6 wow animate__animated animate__fadeInLeft" data-wow-delay=".8s">
                    <div class="single-funfacts funfact-style-two">
                        <i class='bx bxs-award'></i>
                        <h3><span class="odometer" data-count="50">00</span><span class="sign-icon">+</span></h3>
                        <p>Winning Awards</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-cta-box mwidth-1000 wow animate__animated animate__fadeInUp" data-wow-delay="1s">
            <h3>Have any question about us?</h3>
            <p>Don't hesitate to contact us.</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-edit-alt"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
</section>