<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contáctenos</h2>
            <p>Déjanos tu mensaje o consultas aquí</p>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="contact-inner">
            <!-- <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-features-list">
                        <h3>Queremos ser parte de tu historia</h3>
                        <p>Da el primer paso con nosotros y te brindaremos el mejor camino para una mejor productividad en tus procesos.</p>
                        
                        <ul>
                            <li><i class='bx bx-badge-check'></i> Equipo comprometido</li>
                            <li><i class='bx bx-badge-check'></i> Mejora continua de los procesos</li>
                            <li><i class='bx bx-badge-check'></i> Participación activa con cliente</li>
                            <li><i class='bx bx-badge-check'></i> Acompañamiento</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <h3>Nos puedes escribir en el siguiente formulario</h3>
                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Nombre y apellidos">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Teléfono">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Asunto">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="6" required placeholder="Mensaje"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn"><i class='bx bxs-paper-plane'></i>Enviar Mensaje<span></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> -->
            <div class="contact-info">
                <div class="contact-info-content">
                    <h3>Contáctenos vía Teléfono o Email</h3>
                    <h2>
                        <a href="tel:+0881306298615">+569 73979282</a><br>
                        <a href="tel:+0881306298615">+569 62403879</a>
                        <span>OR</span>
                        <a href="mailto:contacto@brineos.com">contacto&#64;brineos.com</a>
                    </h2>
                    <ul class="social">
                        <li><a href="https://x.com/brineoslab" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> -->
                        <li><a href="https://www.linkedin.com/company/brineos" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com/brineoslab" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

