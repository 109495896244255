<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>¿Quiénes somos?</h2>
            <p></p>
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Sobre nosotros</span>
                    <h2>¿Qué nos define?</h2>
                    <p>Brineos es una empresa joven, comprometida con la calidad e innovación. Ofrecemos nuestros servicios para satisfacer las necesidades tecnológicas de nuestros clientes.</p>
                    <p>Nuestro principal objetivo es poder lograr integrar a nuestros clientes como parte de nuestra familia, donde nosotros nos encargamos de mejorar los procesos y hacer cumplir los sueños nuestros socios.</p>
                    
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/team-image/innovamos.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/team-image/colaborar.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title"></span>
                    <h2>¿Qué ofrecemos?</h2>
                    <p>Trabajar en un entorno compartido y transparente con nuestros clientes. Implementando la mejora continua en los planes estratégicos de la compañía.</p>
                                       
                </div>
            </div>
           
        </div>
    </div>
</section>

<!-- <section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nuestro equipo</h2>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team-box wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                    <div class="image">
                        <img src="assets/img/team-image/img1.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Cesar Troncoso</h3>
                        <span>Socio y fundador</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team-box wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <div class="image">
                        <img src="assets/img/team-image/img2.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Francisco Montoya</h3>
                        <span>Socio y fundador</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team-box wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                    <div class="image">
                        <img src="assets/img/team-image/img3.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Lee Munroe</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team-box wow animate__animated animate__fadeInUp" data-wow-delay=".8s">
                    <div class="image">
                        <img src="assets/img/team-image/img4.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Calvin Klein</h3>
                        <span>Support</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->




<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>Cuéntanos los detalles del proyecto que tienes en mente.</h2>
            <p>Nosotros tenemos la mayor disposición de asesorarte para dar la mejor solución.</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Contáctanos<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>